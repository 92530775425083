export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_FULFILLED = "LOGIN_FULFILLED";
export const LOGIN_REJECTED = "LOGIN_REJECTED";

export const REGISTER_PENDING = "REGISTER_PENDING";
export const REGISTER_FULFILLED = "REGISTER_FULFILLED";
export const REGISTER_REJECTED = "REGISTER_REJECTED";

export const LOGOUT_PENDING = "LOGOUT_PENDING";
export const LOGOUT_FULFILLED = "LOGOUT_FULFILLED";
export const LOGOUT_REJECTED = "LOGOUT_REJECTED";

export const INIT_PASSWORD_PENDING = "INIT_PASSWORD_PENDING";
export const INIT_PASSWORD_FULFILLED = "INIT_PASSWORD_FULFILLED";
export const INIT_PASSWORD_REJECTED = "INIT_PASSWORD_REJECTED";

export const FORGOT_PASSWORD_PENDING = "FORGOT_PASSWORD_PENDING";
export const FORGOT_PASSWORD_FULFILLED = "FORGOT_PASSWORD_FULFILLED";
export const FORGOT_PASSWORD_REJECTED = "FORGOT_PASSWORD_REJECTED";

export const FORGOT_PASSWORD_CONFIRM_PENDING =
  "FORGOT_PASSWORD_CONFIRM_PENDING";
export const FORGOT_PASSWORD_CONFIRM_FULFILLED =
  "FORGOT_PASSWORD_CONFIRM_FULFILLED";
export const FORGOT_PASSWORD_CONFIRM_REJECTED =
  "FORGOT_PASSWORD_CONFIRM_REJECTED";

export const REGISTER_VENDOR_PENDING = "REGISTER_VENDOR_PENDING";
export const REGISTER_VENDOR_FULFILLED = "REGISTER_VENDOR_FULFILLED";
export const REGISTER_VENDOR_REJECTED = "REGISTER_VENDOR_REJECTED";

export const REGISTER_VENDOR_SEND_CODE_PENDING =
  "REGISTER_VENDOR_SEND_CODE_PENDING";
export const REGISTER_VENDOR_SEND_CODE_FULFILLED =
  "REGISTER_VENDOR_SEND_CODE_FULFILLED";
export const REGISTER_VENDOR_SEND_CODE_REJECTED =
  "REGISTER_VENDOR_SEND_CODE_REJECTED";

export const REGISTER_VENDOR_CONFIRM_PENDING =
  "REGISTER_VENDOR_CONFIRM_PENDING";
export const REGISTER_VENDOR_CONFIRM_FULFILLED =
  "REGISTER_VENDOR_CONFIRM_FULFILLED";
export const REGISTER_VENDOR_CONFIRM_REJECTED =
  "REGISTER_VENDOR_CONFIRM_REJECTED";

export const RETURN_EMPLOYEE_SCORE_PENDING = "RETURN_EMPLOYEE_SCORE_PENDING";
export const RETURN_EMPLOYEE_SCORE_FULFILLED =
  "RETURN_EMPLOYEE_SCORE_FULFILLED";
export const RETURN_EMPLOYEE_SCORE_REJECTED = "RETURN_EMPLOYEE_SCORE_REJECTED";

export const SET_COMPLIST_PENDING = "SET_COMPLIST_PENDING";
export const SET_COMPLIST_FULFILLED = "SET_COMPLIST_FULFILLED";
export const SET_COMPLIST_REJECTED = "SET_COMPLIST_REJECTED";

export const SAVE_NEW_PASSWORD_PENDING = "SAVE_NEW_PASSWORD_PENDING";
export const SAVE_NEW_PASSWORD_FULFILLED = "SAVE_NEW_PASSWORD_FULFILLED";
export const SAVE_NEW_PASSWORD_REJECTED = "SAVE_NEW_PASSWORD_REJECTED";

export const SET_NEW_PASSWORD_PENDING = "SET_NEW_PASSWORD_PENDING";
export const SET_NEW_PASSWORD_FULFILLED = "SET_NEW_PASSWORD_FULFILLED";
export const SET_NEW_PASSWORD_REJECTED = "SET_NEW_PASSWORD_REJECTED";

export const SAVE_STATISTICS_PENDING = "SAVE_STATISTICS_PENDING";
export const SAVE_STATISTICS_FULFILLED = "SAVE_STATISTICS_FULFILLED";
export const SAVE_STATISTICS_REJECTED = "SAVE_STATISTICS_REJECTED";

export const REFRESH_TOKEN_PENDING = "REFRESH_TOKEN_PENDING";
export const REFRESH_TOKEN_FULFILLED = "REFRESH_TOKEN_FULFILLED";
export const REFRESH_TOKEN_REJECTED = "REFRESH_TOKEN_REJECTED";

export const GET_CAPTCHA_PENDING = "GET_CAPTCHA_PENDING";
export const GET_CAPTCHA_FULFILLED = "GET_CAPTCHA_FULFILLED";
export const GET_CAPTCHA_REJECTED = "GET_CAPTCHA_REJECTED";

export const GET_LOGIN_ANNOUCEMENT = "GET_LOGIN_ANNOUCEMENT";

export const REGISTER_CONFIRM_SMS_CODE_PENDING =
  "REGISTER_CONFIRM_SMS_CODE_PENDING";
export const REGISTER_CONFIRM_SMS_CODE_FULFILLED =
  "REGISTER_CONFIRM_SMS_CODE_FULFILLED";
export const REGISTER_CONFIRM_SMS_CODE_REJECTED =
  "REGISTER_CONFIRM_SMS_CODE_REJECTED";

export const LOGIN_SETTINGS_PENDING = "LOGIN_SETTINGS_PENDING";
export const LOGIN_SETTINGS_FULFILLED = "LOGIN_SETTINGS_FULFILLED";
export const LOGIN_SETTINGS_REJECTED = "LOGIN_SETTINGS_REJECTED";
