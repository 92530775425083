import React from "react";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Styles
import "./styles.scss";
import { FaSearch } from "react-icons/fa";

const Games = () => {
  return (
    <>
      <main className="main-container">
        <Header />
        <section className="games-page page-container">
          <div className="games">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col>
                  <div className="flex flex-column justify-content-center">
                    <a
                      href="/games/score-hunt"
                      className="competition-link competition-score-hunt"
                    >
                      <span>
                        <FaSearch
                          style={{
                            width: "80px",
                            height: "80px",
                            marginTop: "10px",
                          }}
                        />
                      </span>
                      <span>Puan Avı</span>
                    </a>
                  </div>
                </Col>

                <Col>
                  <div className="flex flex-column justify-content-center">
                    <a href="/games/guess-picture" className="competition-link">
                      <span></span>
                      <span>Resmi Tahmin Et</span>
                    </a>
                  </div>
                </Col>

                <Col>
                  <div className="flex flex-column justify-content-center">
                    <a href="/games/word-puzzles" className="competition-link">
                      <span></span>
                      <span>Kelime Bulmaca</span>
                    </a>
                  </div>
                </Col>

                <Col>
                  <div className="flex flex-column justify-content-center">
                    <a
                      href="/games/question-wheel"
                      className="competition-link"
                    >
                      <span></span>
                      <span>Soru Çarkı</span>
                    </a>
                  </div>
                </Col>

                <Col>
                  <div className="flex flex-column justify-content-center">
                    <a href="/games/find-mate" className="competition-link">
                      <span></span>
                      <span>Eşini Bul</span>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Games;
