import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../../../redux/modules";
import showErrMessage from "../../../components/ShowErrMessage";
import "./styles.scss";
import "../../../assets/styles/public.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// Toastify
import showMessage from "../../../components/ShowMessage";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const act = useSelector((store) => store.auth);
  const loginSettings = useSelector((store) => store.auth.loginSettings);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [disabledButton, setDisabledButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmMobileNo, setConfirmMobileNo] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [timer, setTimer] = useState(120); // 2 minutes in seconds

  // useEffect(() => {
  //   if (loginSettings) {
  //     console.log("loginSettings1: ", loginSettings);
  //   }
  // }, [loginSettings]);

  useEffect(() => {
    if (showConfirmModal) {
      setTimer(120); // Reset timer to 2 minutes
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setShowConfirmModal(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [showConfirmModal]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useEffect(() => {
    if (!showConfirmModal) {
      setConfirmMobileNo("");
      setConfirmCode("");
    }
  }, [showConfirmModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (act.isAuthenticated && act.authUser) {
        history.push("/");
      }
    }, 200);

    return () => clearTimeout(timer);
  }, [act.isAuthenticated, act.authUser, history]);

  const handleConfirmCode = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() !== false) {
      dispatch(authAction.registerVendorConfirm(confirmCode, confirmMobileNo))
        .then((e) => {
          if (e.isHttpSuccess) {
            showMessage("Kayıt Başarıyla Oluşturuldu.");
            setShowConfirmModal(false);
          }
        })
        .catch((err) => {
          if (err.isHttpError) {
            showErrMessage(err.message);
          }
        });
    }
  };

  const login = async (e, username, password) => {
    e.preventDefault();

    setLoading(true);
    setDisabledButton(true);
    localStorage.setItem("username", username);

    try {
      await dispatch(
        authAction.login({
          username,
          password,
        })
      );
      setLoading(false);
      setDisabledButton(false);
      history.push("/");
      dispatch(authAction.saveStatistics());
    } catch (err) {
      console.log("err", err);
      setLoading(false);
      setDisabledButton(false);
      if (err && err.isHttpError) {
        showErrMessage(err.message);
        console.log("err: ", err);
        if (err.error.response.data.code === 1034) {
          setShowConfirmModal(true);
          setConfirmMobileNo(err?.error?.response?.data?.fields[0]);
        }
      }
    }
  };

  const handlePasswordTypeChange = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  return (
    <section>
      <div className="container-fluid">
        <div className="row align-items-stretch">
          <div className="col-lg-6 bg-gray-lightest">
            <div className="row min-vh-100 align-items-center justify-content-center">
              <div className="col-xxl-7 col-lg-10 col-md-8 col-10 py-md-8 py-5">
                <div className="text-center mb-6">
                  {loginSettings?.logo && (
                    <img
                      src={loginSettings?.logo}
                      alt="logo"
                      className="auth-logo"
                    />
                  )}
                </div>
                <h5 className="lh-base text-center fw-normal mb-4">
                  Hoşgeldiniz, kullanıcı adı ve şifreniz ile giriş
                  yapabilirsiniz.
                </h5>
                <form action="">
                  <div className="form-floating shadow-lg mb-4 rounded-3">
                    <input
                      type="text"
                      className="form-control border-0 rounded-3 px-4"
                      placeholder="Kullanıcı Adı"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <label htmlFor="loginUsername" className="px-4">
                      <i className="fas fa-user me-3"></i>Kullanıcı Adı veya
                      Telefon Numarası
                    </label>
                  </div>
                  <div className="form-floating shadow-lg mb-4 rounded-3">
                    <input
                      type={passwordType}
                      className="form-control border-0 rounded-3 px-4"
                      id="loginPassword"
                      placeholder="Şifre"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="loginPassword" className="px-4">
                      <i className="fas fa-lock me-3"></i>Şifre
                    </label>
                    {password?.length > 0 && (
                      <>
                        <FaEye
                          className={
                            passwordType === "password"
                              ? "d-none password-eye"
                              : "password-eye"
                          }
                          onClick={handlePasswordTypeChange}
                        />
                        <FaEyeSlash
                          className={
                            passwordType === "text"
                              ? "d-none password-eye-slash"
                              : "password-eye-slash"
                          }
                          onClick={handlePasswordTypeChange}
                        />
                      </>
                    )}
                  </div>
                  <div className="text-end mb-5">
                    <Link to={{ pathname: "/passwordReset" }}>
                      Şifremi Unuttum
                    </Link>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 rounded-3 w-100 mb-4"
                    disabled={disabledButton || loading}
                    onClick={(e) => login(e, username, password)}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm me-3"></span>
                    )}
                    Giriş Yap <i className="fas fa-arrow-right ms-3"></i>
                  </button>
                  <div className="text-center text-gray small">
                    Hesabınız yok mu?{" "}
                    <Link to={{ pathname: "/signUp" }}>Yeni Üye Ol</Link>
                  </div>
                </form>

                <div className="text-center mt-md-8 mt-5">
                  <p>
                    <a
                      href="tel:08505325787"
                      className="text-gray-darkest text-decoration-none"
                    >
                      <i className="fas fa-phone-alt me-3"></i>0850 532 5787
                    </a>
                  </p>
                  <p className="fw-lighter opacity-50">
                    Copyright © 2024 Jarvis. Tüm Hakları Saklıdır.
                  </p>
                  <p className="small mb-0 opacity-50">
                    <a
                      href="#"
                      className="text-decoration-none text-reset me-3"
                    >
                      Aydınlatma Metni
                    </a>
                    <a href="#" className="text-decoration-none text-reset">
                      Kişisel Veriler Hakkında Bildirim
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-lg-block d-none bg-blue">
            <div className="row min-vh-100 align-items-center justify-content-center">
              <div className="col-xxl-8 col-lg-10 py-md-8 py-5">
                <div className="swiper sliderLogin">
                  <div className="swiper-wrapper mb-md-8 mb-5">
                    {loginSettings?.banner && (
                      <div className="bg-none">
                        <div className="text-white">
                          <img
                            src={loginSettings?.banner}
                            alt=""
                            className="login-banner mb-5"
                          />

                          {loginSettings?.loginPageTitle && (
                            <h2 className="text-white mb-3">
                              {loginSettings?.loginPageTitle}
                            </h2>
                          )}

                          {loginSettings?.loginPageDescription && (
                            <p className="mb-4">
                              {loginSettings?.loginPageDescription}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="swiper-pagination swiper-pagination-white"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmModal && (
        <div
          className="modal fade show"
          id="confirmModal"
          tabIndex="-1"
          aria-labelledby="confirmModalLabel"
          aria-hidden="true"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          data-bs-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="confirmModalLabel">
                  Hesabınızı doğrulayın
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowConfirmModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p className="text-center">
                  Kalan Süre:&nbsp;
                  <span style={{ fontWeight: 600 }}>{formatTime(timer)}</span>
                </p>
                <p>
                  Hesabınızı doğrulamak için telefonunuza gönderilen doğrulama
                  kodunu giriniz.
                </p>
                <div className="form-floating shadow-lg mb-4 rounded-3">
                  <input
                    type="text"
                    className="form-control border-0 rounded-3 px-4"
                    placeholder="Doğrulama Kodu"
                    value={confirmCode}
                    onChange={(e) => setConfirmCode(e.target.value)}
                  />
                  <label htmlFor="loginConfirmCode" className="px-4">
                    <i className="fas fa-user me-3"></i>Doğrulama Kodu
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="w-100 btn btn-blue py-2 px-sm-4 px-3 rounded-3"
                  onClick={handleConfirmCode}
                  disabled={!confirmMobileNo || !confirmCode}
                >
                  Gönder <i className="fas fa-arrow-right ms-3"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Login;
