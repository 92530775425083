export const GET_ACADEMY_LIST_PENDING = "GET_ACADEMY_LIST_PENDING";
export const GET_ACADEMY_LIST_FULFILLED = "GET_ACADEMY_LIST_FULFILLED";
export const GET_ACADEMY_LIST_REJECTED = "GET_ACADEMY_LIST_REJECTED";

export const GET_ACADEMY_CATEGORY_LIST_PENDING =
  "GET_ACADEMY_CATEGORY_LIST_PENDING";
export const GET_ACADEMY_CATEGORY_LIST_FULFILLED =
  "GET_ACADEMY_CATEGORY_LIST_FULFILLED";
export const GET_ACADEMY_CATEGORY_LIST_REJECTED =
  "GET_ACADEMY_CATEGORY_LIST_REJECTED";

export const POST_ACADEMY_CATEGORY_FILTER_PENDING =
  "POST_ACADEMY_CATEGORY_FILTER_PENDING";
export const POST_ACADEMY_CATEGORY_FILTER_FULFILLED =
  "POST_ACADEMY_CATEGORY_FILTER_FULFILLED";
export const POST_ACADEMY_CATEGORY_FILTER_REJECTED =
  "POST_ACADEMY_CATEGORY_FILTER_REJECTED";

export const GET_ACADEMY_DETAIL_LIST_PENDING =
  "GET_ACADEMY_DETAIL_LIST_PENDING";
export const GET_ACADEMY_DETAIL_LIST_FULFILLED =
  "GET_ACADEMY_DETAIL_LIST_FULFILLED";
export const GET_ACADEMY_DETAIL_LIST_REJECTED =
  "GET_ACADEMY_DETAIL_LIST_REJECTED";

export const GET_ACADEMY_QUESTIONS_DETAIL_LIST_PENDING =
  "GET_ACADEMY_QUESTIONS_DETAIL_LIST_PENDING";
export const GET_ACADEMY_QUESTIONS_DETAIL_LIST_FULFILLED =
  "GET_ACADEMY_QUESTIONS_DETAIL_LIST_FULFILLED";
export const GET_ACADEMY_QUESTIONS_DETAIL_LIST_REJECTED =
  "GET_ACADEMY_QUESTIONS_DETAIL_LIST_REJECTED";

export const GET_VIDEO_DETAIL_PENDING = "GET_VIDEO_DETAIL_PENDING";
export const GET_VIDEO_DETAIL_FULFILLED = "GET_VIDEO_DETAIL_FULFILLED";
export const GET_VIDEO_DETAIL_REJECTED = "GET_VIDEO_DETAIL_REJECTED";

export const GET_VIDEO_SECONDS_PENDING = "GET_VIDEO_SECONDS_PENDING";
export const GET_VIDEO_SECONDS_FULFILLED = "GET_VIDEO_SECONDS_FULFILLED";
export const GET_VIDEO_SECONDS_REJECTED = "GET_VIDEO_SECONDS_REJECTED";
