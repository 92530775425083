import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../../../redux/modules";
import { useHistory, NavLink } from "react-router-dom";

// Styles
import "./styles.scss";
import { MdRefresh } from "react-icons/md";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SetNewPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pass, setPassType] = useState("password");
  const [newPass, setNewPassType] = useState("password");
  const [newPassConfirm, setNewPassConfirmType] = useState("password");

  const loginSettings = useSelector((store) => store.auth.loginSettings);
  const username = localStorage.getItem("username");

  // useState
  const [captcha, setCaptcha] = useState(null);
  const [captchaText, setCaptchaText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Get Captcha
  const handleGetCaptcha = (e) => {
    if (e) {
      e.preventDefault();
    }

    dispatch(authAction.getCaptcha()).then((res) => {
      if (res.isHttpSuccess) {
        setCaptcha(res?.base64);
        setCaptchaText(res?.text);
      }
    });
  };
  useEffect(() => {
    handleGetCaptcha();
  }, []);

  const handlePasswordTypeChange = (field) => {
    switch (field) {
      case "pass":
        setPassType(pass === "password" ? "text" : "password");
        break;
      case "newPass":
        setNewPassType(newPass === "password" ? "text" : "password");
        break;
      case "newPassConfirm":
        setNewPassConfirmType(
          newPassConfirm === "password" ? "text" : "password"
        );
        break;
      default:
        break;
    }
  };

  const handleSetNewPassword = async (values) => {
    setIsLoading(true);

    try {
      const res = await dispatch(authAction.setNewPassword(values));

      if (res.isHttpSuccess) {
        toast.success("Şifreniz başarıyla değiştirildi!");
        // window.location.href = "/login";
        history.push("/login");
      }
    } catch (error) {
      console.error("Error setting new password:", error);
      toast.error(error?.message || "Bir hata oluştu!");
    } finally {
      setIsLoading(false);
    }
  };

  // Formik Schema for Password Reset
  const schema = yup.object({
    pass: yup.string().required("Eski şifre zorunludur"),
    newPass: yup
      .string()
      .required("Yeni şifre zorunludur")
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.,!])(?=\S+$).{8,}$/,
        "Şifreniz en az 8 karakter, bir büyük harf, bir küçük harf, bir rakam ve bir özel karakter içermelidir!"
      ),
    newPassConfirm: yup
      .string()
      .required("Yeni şifreyi doğrulayınız")
      .oneOf([yup.ref("newPass"), null], "Yeni şifreler eşleşmiyor"),
    captcha: yup
      .string()
      .required("Lütfen Captcha Giriniz!")
      .test("is-captcha-correct", "Captcha yanlış!", function (value) {
        console.log("value: ", value);
        console.log("captchaText: ", captchaText);
        return value === captchaText;
      }),
    changePassType: yup.string().required("Şifre değiştirme türü zorunludur"),
  });

  return (
    <div className="public-container">
      <section className="bg-gray-lightest  set-new-password">
        <div className="container">
          <div className="row justify-content-center py-md-8 py-5">
            <div className="col-xl-4 col-lg-6 col-md-8 col-10">
              <div className="flex justify-content-center mb-4">
                <NavLink to="/login" className="d-inline-block">
                  {loginSettings?.logo && (
                    <img
                      src={loginSettings?.logo}
                      alt="logo"
                      className="auth-logo"
                    />
                  )}
                </NavLink>
              </div>

              <h5 className="lh-base text-center fw-normal mb-3">
                Şifrenizi Değiştirin
              </h5>
              <p className="mb-5 text-center fw-light">
                Yeni şifrenizi girin, sonra doğrulama için CAPTCHA'yı çözün.
              </p>

              <Formik
                initialValues={{
                  tenantName: loginSettings?.tenantName || "",
                  userName: username || "",
                  pass: "",
                  newPass: "",
                  newPassConfirm: "",
                  captcha: "",
                  changePassType: "",
                }}
                validationSchema={schema}
                onSubmit={handleSetNewPassword}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="form-body">
                      <div className="form-el form-el-container">
                        {/* User Name */}
                        <div className="form-floating shadow-lg mb-2 rounded-3">
                          <input
                            type="text"
                            className="form-control border-0 rounded-3 px-4"
                            placeholder="Kullanıcı Adı"
                            name="userName"
                            value={values.userName}
                            readOnly
                          />
                          <label htmlFor="userName" className="px-4">
                            Kullanıcı Adı
                          </label>
                        </div>

                        {/* Old Password */}
                        <div className="form-floating shadow-lg my-2 rounded-3">
                          <input
                            type={pass}
                            className="form-control border-0 rounded-3 px-4"
                            placeholder="Eski Şifre"
                            name="pass"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.pass}
                          />
                          <label htmlFor="pass" className="px-4">
                            Eski Şifre
                          </label>
                          {values.pass?.length > 0 && (
                            <>
                              <FaEye
                                className={
                                  pass === "password"
                                    ? "d-none password-eye"
                                    : "password-eye"
                                }
                                onClick={() => handlePasswordTypeChange("pass")}
                              />
                              <FaEyeSlash
                                className={
                                  pass === "text"
                                    ? "d-none password-eye-slash"
                                    : "password-eye-slash"
                                }
                                onClick={() => handlePasswordTypeChange("pass")}
                              />
                            </>
                          )}
                        </div>
                        {errors.pass && touched.pass && (
                          <span className="errorMessage mb-4">
                            {errors.pass}
                          </span>
                        )}

                        {/* New Password */}
                        <div className="form-floating shadow-lg my-2 rounded-3">
                          <input
                            type={newPass}
                            className="form-control border-0 rounded-3 px-4"
                            placeholder="Yeni Şifre"
                            name="newPass"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPass}
                          />
                          <label htmlFor="newPass" className="px-4">
                            Yeni Şifre
                          </label>
                          {values.newPass?.length > 0 && (
                            <>
                              <FaEye
                                className={
                                  newPass === "password"
                                    ? "d-none password-eye"
                                    : "password-eye"
                                }
                                onClick={() =>
                                  handlePasswordTypeChange("newPass")
                                }
                              />
                              <FaEyeSlash
                                className={
                                  newPass === "text"
                                    ? "d-none password-eye-slash"
                                    : "password-eye-slash"
                                }
                                onClick={() =>
                                  handlePasswordTypeChange("newPass")
                                }
                              />
                            </>
                          )}
                        </div>
                        {errors.newPass && touched.newPass && (
                          <span className="errorMessage">{errors.newPass}</span>
                        )}

                        {/* Confirm New Password */}
                        <div className="form-floating shadow-lg my-2 rounded-3">
                          <input
                            type={newPassConfirm}
                            className="form-control border-0 rounded-3 px-4"
                            placeholder="Yeni Şifreyi Doğrulayın"
                            name="newPassConfirm"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassConfirm}
                          />
                          <label htmlFor="newPassConfirm" className="px-4">
                            Yeni Şifreyi Doğrulayın
                          </label>
                          {values.newPassConfirm?.length > 0 && (
                            <>
                              <FaEye
                                className={
                                  newPassConfirm === "password"
                                    ? "d-none password-eye"
                                    : "password-eye"
                                }
                                onClick={() =>
                                  handlePasswordTypeChange("newPassConfirm")
                                }
                              />
                              <FaEyeSlash
                                className={
                                  newPassConfirm === "text"
                                    ? "d-none password-eye-slash"
                                    : "password-eye-slash"
                                }
                                onClick={() =>
                                  handlePasswordTypeChange("newPassConfirm")
                                }
                              />
                            </>
                          )}
                        </div>
                        {errors.newPassConfirm && touched.newPassConfirm && (
                          <span className="errorMessage">
                            {errors.newPassConfirm}
                          </span>
                        )}

                        {/* Change Password Type */}
                        <div className="form-floating shadow-lg my-2 rounded-3">
                          <select
                            className="form-control border-0 rounded-3 px-4"
                            name="changePassType"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.changePassType}
                          >
                            <option value="">
                              Şifre Değiştirme Süresi Seçin
                            </option>
                            <option value="ONE">1 Ay</option>
                            <option value="THREE">3 Ay</option>
                            <option value="SIX">6 Ay</option>
                          </select>
                          <label htmlFor="changePassType" className="px-4">
                            Şifre Değiştirme Süresi
                          </label>
                        </div>
                        {errors.changePassType && touched.changePassType && (
                          <span className="errorMessage">
                            {errors.changePassType}
                          </span>
                        )}

                        {/* CAPTCHA */}
                        <div className="flex items-center my-2 align-items-center justify-center space-x-4">
                          {captcha && (
                            <img
                              src={`data:image/png;base64,${captcha}`}
                              alt="Captcha"
                              className="my-2 w-100"
                            />
                          )}
                          <MdRefresh
                            size={28}
                            onClick={handleGetCaptcha}
                            className="reload-captcha font-bold  cursor-pointer "
                          />
                        </div>
                        <div className="form-floating shadow-lg my-2 rounded-3">
                          <input
                            className="form-control border-0 rounded-3 px-4"
                            type="text"
                            placeholder="Captcha"
                            name="captcha"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.captcha}
                          />
                          <label htmlFor="captcha" className="px-4">
                            Captcha
                          </label>
                        </div>
                        {errors.captcha && touched.captcha && (
                          <span className="errorMessage">{errors.captcha}</span>
                        )}

                        {/* Submit Button */}
                        <div className="form-btn">
                          <button
                            type="submit"
                            disabled={isLoading}
                            className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 rounded-3 w-100 mb-4"
                          >
                            {isLoading ? (
                              <span className="spinner-border spinner-border-sm me-3"></span>
                            ) : null}
                            Gönder <i className="fas fa-arrow-right ms-3"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SetNewPassword;
