import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileAction, userAction } from "../../../redux/modules";

// Styles
import "../../ProfileOld/styles.scss";

const Score = () => {
  const dispatch = useDispatch();
  // Point All Datatable Items
  const pointHistoryAll = useSelector((store) => store.profile.pointHistoryAll);
  const [state, setState] = useState({
    transactionsCount: 10,
  });

  useEffect(() => {
    dispatch(profileAction.getScore());
    dispatch(profileAction.getProfileAllPointHistory());
  }, []);

  const handleLoadMore = (event) => {
    event.preventDefault();
    setState({
      ...state,
      transactionsCount:
        (state.transactionsCount ? state.transactionsCount : 10) + 10,
    });
  };

  return (
    <>
      <React.Fragment>
        <h2 className="mb-sm-6 mb-4">Puan Geçmişim</h2>
        <p className="mb-sm-5 mb-4 fw-light">
          Kazandığınız puanları bu bölümde detaylı olarak görebilir, takip
          edebilirsiniz.
        </p>

        {pointHistoryAll
          ? pointHistoryAll?.content
              ?.slice(0, state.transactionsCount ? state.transactionsCount : 10)
              .map((item, i) => (
                <div
                  className="d-flex align-items-center justify-content-between py-3 border-bottom"
                  key={i}
                >
                  <span className="text-blue-light">
                    {item.dateCreated.replaceAll("-", ".")}
                  </span>
                  <span className="mx-4">{item.scoreTypeName}</span>
                  {item.scoreType === "SIPARIS" ? (
                    <span className="badge rounded-pill bg-red px-3 py-2 ms-auto">
                      -{item.changedScore} Puan
                    </span>
                  ) : (
                    <span className="badge rounded-pill bg-green px-3 py-2 ms-auto">
                      +{item.changedScore} Puan
                    </span>
                  )}
                </div>
              ))
          : null}
        {pointHistoryAll?.content?.length -
          (state.transactionsCount ? state.transactionsCount : 10) >
        0 ? (
          <div className="text-center mt-sm-6 mt-5">
            <button
              type="button"
              className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3"
              onClick={(event) => handleLoadMore(event)}
            >
              Daha Fazla Göster
            </button>
          </div>
        ) : null}
      </React.Fragment>
    </>
  );
};

export default Score;
