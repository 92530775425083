import * as searchTypes from "./search.type";
import { API } from "../../../services/http.service";

const REQUEST_URL = "";

export const getSearch = (searchText) => async (dispatch) => {
  dispatch({ type: searchTypes.GET_SEARCH_BY_TEXT_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/content/search/all/${searchText}`
    );
    dispatch({
      type: searchTypes.GET_SEARCH_BY_TEXT_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: searchTypes.GET_SEARCH_BY_TEXT_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};
