import { createBrowserHistory } from "history";
import { authAction } from "../modules";

export const history = createBrowserHistory();

/**
 * Middleware to handle authorization errors (401, 403).
 */
export const handleAuthorization =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const { payload } = action;

    if (payload?.status === 401 || payload?.status === 403) {
      dispatch(authAction.logout());
      history.push("/login");
    } else {
      next(action);
    }
  };
