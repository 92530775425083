// Global imports
import { applyMiddleware, compose, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Local imports
import rootReducer from "./modules/root.reducer";
import { persist } from "../config/store.config";
import { isActiveReduxDebugger } from "../config/dev.config";
import { Middlewares } from "./middlewares";

const persistConfig = {
  key: persist.key,
  storage,
  whitelist: persist.whitelist,
  version: persist.version,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create Store
export const configureStore = (preloadedState) => {
  const middlewares = [...Middlewares.get()];

  const composeEnhancers =
    (isActiveReduxDebugger && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const composed = [applyMiddleware(...middlewares)];

  const store = createStore(
    persistedReducer,
    preloadedState,
    composeEnhancers(...composed)
  );

  const persistor = persistStore(store);

  return { store, persistor };
};
