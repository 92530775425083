import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import FindMate from "../../../assets/images/games/find-mate/find-mate-banner.png";
import GuessPicture from "../../../assets/images/games/guess-picture/guess-picture-banner.png";
import WordPuzzles from "../../../assets/images/games/word-puzzles/word-puzzles.png";
import LuckyWheel from "../../../assets/images/games/lucky-wheel/luckywheel-banner.png";
import ScoreHunt from "../../../assets/images/games/score-hunt/score-hunt-banner.png";

// Styles
import "./styles.scss";

import $ from "jquery";

const HomeGames = () => {
  useEffect(() => {
    var sliderGames = ".sliderGames";
    if ($(sliderGames).length) {
      // eslint-disable-next-line no-undef
      var swiper = new Swiper(sliderGames, {
        breakpoints: {
          0: {
            slidesPerView: 1.5,
          },
          576: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
        spaceBetween: -8,
        pagination: {
          el: sliderGames + " " + ".swiper-pagination",
          clickable: true,
        },
      });
    }
  }, []);

  return (
    <section className="pb-sm-10 pb-5">
      <div className="container px-0">
        <h1 className="text-center mb-5">Yarışmalar</h1>
        <div className="swiper sliderGames">
          <div className="swiper-wrapper mb-sm-5 mb-4">
            <div className="swiper-slide h-auto p-3">
              <div className="gameItem h-100">
                <div className="bg-white shadow-sm rounded-3 px-4 pb-sm-5 pb-4 text-center h-100">
                  <div className="image shadow-sm bg-white rounded-circle">
                    <img src={FindMate} alt="" />
                  </div>
                  <h2 className="mb-4 fw-bold text-blue lh-sm">Eşini Bul</h2>
                  <p className="mb-4 small">
                    Eşini bul oyunu ile eğlenceli vakit geçirin.
                  </p>
                  <Link
                    to="/games/find-mate"
                    className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3"
                  >
                    Başla
                  </Link>
                </div>
              </div>
            </div>

            <div className="swiper-slide h-auto p-3">
              <div className="gameItem h-100">
                <div className="bg-white shadow-sm rounded-3 px-4 pb-sm-5 pb-4 text-center h-100">
                  <div className="image shadow-sm bg-white rounded-circle">
                    <img src={WordPuzzles} alt="" />
                  </div>
                  <h2 className="mb-4 fw-bold text-blue lh-sm">
                    Kelime Bulmaca
                  </h2>
                  <p className="mb-4 small">
                    Kelime bulmaca oyunu ile eğlenceli vakit geçirin.
                  </p>
                  <Link
                    to="/games/word-puzzles"
                    className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3"
                  >
                    Başla
                  </Link>
                </div>
              </div>
            </div>

            <div className="swiper-slide h-auto p-3">
              <div className="gameItem Link">
                <div className="bg-white shadow-sm rounded-3 px-4 pb-sm-5 pb-4 text-center h-100">
                  <div className="image shadow-sm bg-white rounded-circle">
                    <img src={ScoreHunt} alt="" />
                  </div>
                  <h2 className="mb-4 fw-bold text-blue lh-sm">Puan Avı</h2>
                  <p className="mb-4 small">
                    Puan Avı sembolünü bulunan ürünü bulun ve soruya ulaşın.
                  </p>
                  <Link
                    to="/games/score-hunt"
                    className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3"
                  >
                    Başla
                  </Link>
                </div>
              </div>
            </div>

            <div className="swiper-slide h-auto p-3">
              <div className="gameItem h-100">
                <div className="bg-white shadow-sm rounded-3 px-4 pb-sm-5 pb-4 text-center h-100">
                  <div className="image shadow-sm bg-white rounded-circle">
                    <img src={GuessPicture} alt="" />
                  </div>
                  <h2 className="mb-4 fw-bold text-blue lh-sm">
                    Resmi Tahmin Et
                  </h2>
                  <p className="mb-4 small">
                    Resmi tahmin edin ve hediye kazanma şansı yakalayın.
                  </p>
                  <Link
                    to="/games/guess-picture"
                    className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3"
                  >
                    Başla
                  </Link>
                </div>
              </div>
            </div>

            <div className="swiper-slide h-auto p-3">
              <div className="gameItem h-100">
                <div className="bg-white shadow-sm rounded-3 px-4 pb-sm-5 pb-4 text-center h-100">
                  <div className="image shadow-sm bg-white rounded-circle">
                    <img src={LuckyWheel} alt="" />
                  </div>
                  <h2 className="mb-4 fw-bold text-blue lh-sm">Soru Çarkı</h2>
                  <p className="mb-4 small">
                    Soru çarkı oyunu ile eğlenceli vakit geçirin.
                  </p>
                  <Link
                    to="/games/question-wheel"
                    className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3"
                  >
                    Başla
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};
export default HomeGames;
