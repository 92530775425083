import React, { useState, useEffect } from "react";
import { useTimer } from "react-timer-hook";

const Timer = ({ expiryTimestamp, onExpire = () => {} }) => {
  const [hasExpired, setHasExpired] = useState(false);

  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      console.warn("onExpire called");
      setHasExpired(true);
    },
  });

  useEffect(() => {
    if (hasExpired) {
      onExpire(true);
    }
  }, [hasExpired, onExpire]);

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "60px" }}>
        <span>{String(minutes).padStart(2, "0")}</span>:
        <span>{String(seconds).padStart(2, "0")}</span>
      </div>
    </div>
  );
};

export default Timer;
