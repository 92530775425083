import { authAction } from "../modules";

/**
 * Handles authentication-related actions such as token refresh and logout.
 */
export const handleAuthenticated =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    const { payload } = action;

    if (payload?.status === 401) {
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refreshToken");

      if (token && refreshToken) {
        try {
          const res = await dispatch(
            authAction.refreshToken(token, refreshToken)
          );
          if (res.token) {
            localStorage.setItem("token", res.token);
            localStorage.setItem("refreshToken", res.refreshToken);
            window.location.replace("/");
          }
        } catch (error) {
          console.error("Token refresh error: ", error);
          dispatch(authAction.logout(token, refreshToken));
        }
      }
    } else if (payload?.status === 403) {
      localStorage.clear();
      window.location.replace("/login");
    } else {
      next(action);
    }
  };
