import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { usePrevious } from "./index";

export default function useHandleRouteWhenAuth() {
  const history = useHistory();
  const location = useLocation();

  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);
  const isPrevAuthenticated = usePrevious(isAuthenticated);

  useEffect(() => {
    if (!isPrevAuthenticated && isAuthenticated) {
      const nextRoute =
        (location.state && location.state.from) || location.pathname || "/";
      history.push(nextRoute);
    }
    return () => {};
  }, [isAuthenticated, isPrevAuthenticated, location, history]);
}
