/* eslint-disable no-duplicate-case */
import * as aboutTypes from "./about.type";

const initialState = {
  aboutUsData: null,
  aboutUsByType: null,
  footerAboutUs: null,
  footerAboutUsTyped: null,
  error: null,
  isLoading: false,
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${aboutTypes.GET_ABOUT_US_PENDING}`:
    case `${aboutTypes.GET_ABOUT_US_BY_TYPE_PENDING}`:
    case `${aboutTypes.GET_FOOTER_ABOUT_US_REJECTED}`:
    case `${aboutTypes.GET_FOOTER_ABOUT_US_LIST_BY_TYPE_REJECTED}`:
      return { ...state, isLoading: true, error: null };

    case `${aboutTypes.GET_ABOUT_US_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        aboutUsData: payload.data,
      };
    case `${aboutTypes.GET_ABOUT_US_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        aboutUsData: null,
      };

    case `${aboutTypes.GET_ABOUT_US_BY_TYPE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        aboutUsByType: payload.data,
      };
    case `${aboutTypes.GET_ABOUT_US_BY_TYPE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        aboutUsByType: null,
      };

    case `${aboutTypes.GET_FOOTER_ABOUT_US_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        footerAboutUs: payload.data,
      };
    case `${aboutTypes.GET_FOOTER_ABOUT_US_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        footerAboutUs: null,
      };

    case `${aboutTypes.GET_FOOTER_ABOUT_US_LIST_BY_TYPE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        footerAboutUsTyped: payload.data,
      };
    case `${aboutTypes.GET_FOOTER_ABOUT_US_LIST_BY_TYPE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        footerAboutUsTyped: null,
      };

    default:
      return state;
  }
};

export default reducer;
