import React from "react";

import DashboardBanner from "../../../assets/images/dashboard-banner.png";

const Dashboard = () => {
  return (
    <>
      <React.Fragment>
        <h2 className="mb-sm-6 mb-4">Dashboard</h2>
        <p className="mb-sm-5 mb-4 fw-light"></p>
        <img src={DashboardBanner} alt="" className="img-fluid" />
      </React.Fragment>
    </>
  );
};

export default Dashboard;
