import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { gamesAction, profileAction } from "../../../redux/modules";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Girls from "../../../assets/images/games/lucky-wheel/girls.png";

const GameOver = ({ activeGame, setIsFinish }) => {
  const dispatch = useDispatch();
  const [responseMessage, setResponseMessage] = useState("");
  const [point, setPoint] = useState(null);

  useEffect(() => {
    if (activeGame) {
      dispatch(gamesAction.setFindMate(activeGame)).then((res) => {
        let message = res.data.responseText;
        message = message.replace("{point}", res.data.score);
        setResponseMessage(message);
        setPoint(res.data.score);
        setIsFinish(true);
        setTimeout(() => {
          dispatch(profileAction.getLoginUserDetail());
        }, 2000);
      });
    }

    return () => {};
  }, [dispatch, activeGame]);

  return (
    <Row className="justify-content-md-center">
      <Col xs={12} className="text-center find-mate-win active">
        <h1>Tebrikler Kazandınız!</h1>
        {responseMessage && <p className="!m-0">{responseMessage}</p>}

        <div className="wrapper-reward active">
          <img src={Girls} alt="" />
          {point && <h4>{point}</h4>}
        </div>
      </Col>
    </Row>
  );
};

export default GameOver;
