import * as cartTypes from "./cart.type";

const initialState = {
  cartData: null,
  paymentModule: null,
  error: null,
  isLoading: false,
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${cartTypes.GET_CART_PENDING}`:
    case `${cartTypes.REMOVE_CART_ITEM_BY_ID_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${cartTypes.GET_CART_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        cartData: payload.data,
      };
    case `${cartTypes.GET_CART_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        cartData: null,
        error: payload,
      };

    case `${cartTypes.GET_PAYMENT_MODULE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        paymentModule: payload.data,
      };
    case `${cartTypes.GET_PAYMENT_MODULE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        paymentModule: null,
        error: payload,
      };

    // Add To Cart
    case `${cartTypes.ADD_TO_CART_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        cartData: payload.data,
      };
    case `${cartTypes.ADD_TO_CART_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        cartData: null,
        error: payload,
      };

    case `${cartTypes.REMOVE_CART_ITEM_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        cartData: payload.data,
      };
    case `${cartTypes.REMOVE_CART_ITEM_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case `${cartTypes.REMOVE_ALL_ITEMS}`:
      return {
        ...state,
        isLoading: false,
        cartData: null,
      };

    default:
      return state;
  }
};

export default reducer;
