import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import style from "./styles.module.scss";

export default function showProductMessage(text) {
  const ToastContent = ({ closeToast }) => {
    const history = useHistory();

    const handleGoToCart = () => {
      history.push("/cart");
      closeToast();
    };

    return (
      <div className={style.toast}>
        <p>{text}</p>
        <div className={style.toastButtons}>
          <button className={style.toastButtonSuccess} onClick={handleGoToCart}>
            Sepete Git
          </button>
          <button className={style.toastButtonCancel} onClick={closeToast}>
            Alışverişe Devam Et
          </button>
        </div>
      </div>
    );
  };

  toast.success(<ToastContent />, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: false,
  });
}
