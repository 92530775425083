import React from "react";
import ReactLoading from "react-loading";

const GlobalLoader = ({ isLoading, color = "#05aa5d", position = "fixed" }) => {
  const loadingStatus =
    typeof isLoading === "object" ? isLoading.isLoading : isLoading;

  return (
    <>
      {loadingStatus === true && (
        <div
          style={{
            textAlign: "-webkit-center",
            position: position,
            top: "50%",
            left: "50%",
            marginLeft: "-32px",
            marginTop: "-32px",
          }}
        >
          <ReactLoading type="spinningBubbles" color={color} />
        </div>
      )}
    </>
  );
};

export default GlobalLoader;
