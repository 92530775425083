import * as homeTypes from "./home.type";

const initialState = {
  announcementList: null,
  surveyList: null,
  selectedOption: null,
  disclaimerList: null,
  activityList: null,
  brandList: null,
  productList: [],
  setAnswer: null,
  cartAdd: null,
  surveyResult: null,
  error: null,
  isLoading: false,
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${homeTypes.GET_ANNOUNCEMENTLIST_PENDING}`:
    case `${homeTypes.SAVE_SURVEY_ANSWER_PENDING}`:
    case `${homeTypes.SAVE_DISCLAIMER_CHECK_PENDING}`:
    case `${homeTypes.GET_DISCLAIMER_BY_FIRMID_PENDING}`:
    case `${homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING}`:
    case `${homeTypes.GET_PRODUCT_LIST_PENDING}`:
    case `${homeTypes.GET_BRAND_LIST_PENDING}`:
    case `${homeTypes.GET_SURVEY_LIST_HOMEPAGE_PENDING}`:
    case `${homeTypes.SET_SURVEY_ANSWER_HOMEPAGE_PENDING}`:
    case `${homeTypes.SET_ADD_TO_CART_PENDING}`:
    case `${homeTypes.GET_SURVEY_RESULT_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${homeTypes.GET_ANNOUNCEMENTLIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        announcementList: payload,
      };

    case `${homeTypes.GET_ANNOUNCEMENTLIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case `${homeTypes.SAVE_SURVEY_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };

    case `${homeTypes.SAVE_SURVEY_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
      };

    case `${homeTypes.GET_DISCLAIMER_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        disclaimerList: payload.data,
      };

    case `${homeTypes.GET_DISCLAIMER_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
      };

    case `${homeTypes.SAVE_DISCLAIMER_CHECK_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
      };

    case `${homeTypes.SAVE_DISCLAIMER_CHECK_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
      };

    case `${homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        activityList: payload.data,
      };

    case `${homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
      };

    case `${homeTypes.SELECTED_OPTION}`:
      return {
        ...state,
        selectedOption: payload,
      };

    case `${homeTypes.GET_BRAND_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandList: payload.data,
      };

    case `${homeTypes.GET_BRAND_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
        brandList: [],
      };

    case `${homeTypes.GET_PRODUCT_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        productList: payload.data,
      };

    case `${homeTypes.GET_PRODUCT_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
        productList: [],
      };

    case `${homeTypes.GET_SURVEY_LIST_HOMEPAGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        surveyList: payload.data,
      };

    case `${homeTypes.GET_SURVEY_LIST_HOMEPAGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
      };

    case `${homeTypes.SET_SURVEY_ANSWER_HOMEPAGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        setAnswer: payload.data,
      };

    case `${homeTypes.SET_SURVEY_ANSWER_HOMEPAGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
      };

    case `${homeTypes.SET_ADD_TO_CART_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        cartAdd: payload.data,
      };

    case `${homeTypes.SET_ADD_TO_CART_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case `${homeTypes.GET_SURVEY_RESULT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        surveyResult: payload.data,
      };

    case `${homeTypes.GET_SURVEY_RESULT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        surveyResult: null,
        error: payload,
      };

    default:
      return state;
  }
};

export default reducer;
