import * as productType from "./product.type";
import { API } from "../../../services/http.service";

const REQUEST_URL = "";

// Get Product By Id
export const getProductById = (productId) => async (dispatch) => {
  dispatch({ type: productType.GET_PRODUCT_BY_ID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/catalog/product/${productId}`
    );
    dispatch({
      type: productType.GET_PRODUCT_BY_ID_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: productType.GET_PRODUCT_BY_ID_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

// Get Product Rate By Id
export const getProductRateById = (productId) => async (dispatch) => {
  dispatch({ type: productType.GET_PRODUCT_RATE_BY_ID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/catalog/getProductRate/${productId}`
    );
    dispatch({
      type: productType.GET_PRODUCT_RATE_BY_ID_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: productType.GET_PRODUCT_RATE_BY_ID_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Get Product Favourite List
export const getProductFavouriteList = () => async (dispatch) => {
  dispatch({ type: productType.GET_PRODUCT_FAVOURITES_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/catalog/favourite/productList`
    );
    dispatch({
      type: productType.GET_PRODUCT_FAVOURITES_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: productType.GET_PRODUCT_FAVOURITES_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Set Product Rate By Id
export const setProductRateById =
  (productId, ratePoint, comment) => async (dispatch) => {
    dispatch({ type: productType.GET_PRODUCT_RATE_BY_ID_PENDING });

    const data = {
      productId: productId,
      ratePoint: ratePoint,
      comment: comment,
    };

    try {
      const response = await API.post(
        `${REQUEST_URL}/catalog/rate/product`,
        data
      );
      dispatch({
        type: productType.GET_PRODUCT_RATE_BY_ID_FULFILLED,
        payload: response,
      });
      return Promise.resolve(response);
    } catch (error) {
      dispatch({
        type: productType.GET_PRODUCT_RATE_BY_ID_REJECTED,
        payload: error,
      });
      return Promise.reject(error);
    }
  };

// Set Favourite List
export const setFavouriteProductById = (productId) => async (dispatch) => {
  dispatch({ type: productType.SET_FAVOURITE_PRODUCT_BY_ID_PENDING });

  const data = {
    productId: productId,
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/catalog/favouriteProduct/save`,
      data
    );
    dispatch({
      type: productType.SET_FAVOURITE_PRODUCT_BY_ID_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: productType.SET_FAVOURITE_PRODUCT_BY_ID_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Remove Favourite List
export const removeFavouriteProductById = (productId) => async (dispatch) => {
  dispatch({ type: productType.REMOVE_FAVOURITE_PRODUCT_BY_ID_PENDING });

  const data = {
    productId: productId,
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/catalog/favouriteProduct/remove`,
      data
    );
    dispatch({
      type: productType.REMOVE_FAVOURITE_PRODUCT_BY_ID_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: productType.REMOVE_FAVOURITE_PRODUCT_BY_ID_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};
