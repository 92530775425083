export const GET_BRAND_LIST_PENDING = "GET_BRAND_LIST_PENDING";
export const GET_BRAND_LIST_FULFILLED = "GET_BRAND_LIST_FULFILLED";
export const GET_BRAND_LIST_REJECTED = "GET_BRAND_LIST_REJECTED";

export const GET_BRAND_STYLE_LIST_PENDING = "GET_BRAND_STYLE_LIST_PENDING";
export const GET_BRAND_STYLE_LIST_FULFILLED = "GET_BRAND_STYLE_LIST_FULFILLED";
export const GET_BRAND_STYLE_LIST_REJECTED = "GET_BRAND_STYLE_LIST_REJECTED";

export const GET_BRAND_SUBCATEGORY_STYLE_LIST_PENDING =
  "GET_BRAND_SUBCATEGORY_STYLE_LIST_PENDING";
export const GET_BRAND_SUBCATEGORY_STYLE_LIST_FULFILLED =
  "GET_BRAND_SUBCATEGORY_STYLE_LIST_FULFILLED";
export const GET_BRAND_SUBCATEGORY_STYLE_LIST_REJECTED =
  "GET_BRAND_SUBCATEGORY_STYLE_LIST_REJECTED";

export const GET_BRAND_CATEGORY_LIST_PENDING =
  "GET_BRAND_CATEGORY_LIST_PENDING";
export const GET_BRAND_CATEGORY_LIST_FULFILLED =
  "GET_BRAND_CATEGORY_LIST_FULFILLED";
export const GET_BRAND_CATEGORY_LIST_REJECTED =
  "GET_BRAND_CATEGORY_LIST_REJECTED";

export const GET_BRAND_TOPIC_PENDING = "GET_BRAND_TOPIC_PENDING";
export const GET_BRAND_TOPIC_FULFILLED = "GET_BRAND_TOPIC_FULFILLED";
export const GET_BRAND_TOPIC_REJECTED = "GET_BRAND_TOPIC_REJECTED";

export const GET_BRAND_MAGAZINE_PENDING = "GET_BRAND_MAGAZINE_PENDING";
export const GET_BRAND_MAGAZINE_FULFILLED = "GET_BRAND_MAGAZINE_FULFILLED";
export const GET_BRAND_MAGAZINE_REJECTED = "GET_BRAND_MAGAZINE_REJECTED";

export const GET_BRAND_PRODUCT_FAVOURITE_LIST_PENDING =
  "GET_BRAND_PRODUCT_FAVOURITE_LIST_PENDING";
export const GET_BRAND_PRODUCT_FAVOURITE_LIST_FULFILLED =
  "GET_BRAND_PRODUCT_FAVOURITE_LIST_FULFILLED";
export const GET_BRAND_PRODUCT_FAVOURITE_LIST_REJECTED =
  "GET_BRAND_PRODUCT_FAVOURITE_LIST_REJECTED";

export const SET_FAVOURITE_BRAND_STYLE_PENDING =
  "SET_FAVOURITE_BRAND_STYLE_PENDING";
export const SET_FAVOURITE_BRAND_STYLE_FULFILLED =
  "SET_FAVOURITE_BRAND_STYLE_FULFILLED";
export const SET_FAVOURITE_BRAND_STYLE_REJECTED =
  "SET_FAVOURITE_BRAND_STYLE_REJECTED";

export const DELETE_FAVOURITE_BRAND_STYLE_PENDING =
  "DELETE_FAVOURITE_BRAND_STYLE_PENDING";
export const DELETE_FAVOURITE_BRAND_STYLE_FULFILLED =
  "DELETE_FAVOURITE_BRAND_STYLE_FULFILLED";
export const DELETE_FAVOURITE_BRAND_STYLE_REJECTED =
  "DELETE_FAVOURITE_BRAND_STYLE_REJECTED";
