import React from "react";
import PerformanceBanner from "../../../assets/images/performance/performance-banner-grey.jpg";

const FocusInfo = ({ data }) => {
  console.log("data: ", data.data);

  return (
    <div className="wrapper-performance-banner">
      <img src={PerformanceBanner} className="img-performance-banner" alt="" />

      {data?.data?.length > 0
        ? data?.data?.map((item, index) => (
            <div key={index} className="item-performance-banner">
              <span>+ % {parseFloat(item.percentage).toFixed(1)}</span>
            </div>
          ))
        : "Data bulunamadı!"}
    </div>
  );
};

export default FocusInfo;
