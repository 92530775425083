/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeAction, profileAction } from "../../../redux/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

// Styles
import "./styles.scss";

// Toastify
import showMessage from "../../../components/ShowMessage";
import showErrMessage from "../../../components/ShowErrMessage";

import SurveyBanner from "../../../assets/images/bg-survey.png";

const HomeSurvey = () => {
  const dispatch = useDispatch();
  const surveyList = useSelector((store) => store.home.surveyList);

  useEffect(() => {
    dispatch(homeAction.getHomepageSurvey()).then((e) => {
      if (e.isHttpSuccess) {
        if (e?.data?.content) {
          for (const survey of e.data.content) {
            if (survey.answeredSurvey === true) {
              dispatch(homeAction.getSurveyResult(survey.id));
            }
          }
        }
      }
    });
    return () => {};
  }, [dispatch]);

  const vote = (e, surveyId) => {
    e.preventDefault();
    const input = document.querySelector("input[name='surveyChoice']:checked");

    if (input) {
      dispatch(homeAction.setHomepageSurvey(parseInt(surveyId), input.value))
        .then((res) => {
          if (res.isHttpSuccess) {
            dispatch(homeAction.getHomepageSurvey()).then((e) => {
              if (e.isHttpSuccess) {
                if (e?.data?.content) {
                  for (const survey of e.data.content) {
                    if (survey.answeredSurvey === true) {
                      dispatch(homeAction.getSurveyResult(survey.id)).then(
                        (response) => {
                          showMessage(
                            `Anketimizi cevapladınız ve ${response.data.score} puan kazandınız!`
                          );
                        }
                      );
                    }
                  }
                }
              }
            });

            setTimeout(() => {
              dispatch(profileAction.getLoginUserDetail());
            }, 2000);
          } else {
            showErrMessage(
              "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz!"
            );
          }
        })
        .catch((err) => {
          if (err.isHttpError) {
            showErrMessage(err.message);
          }
        });
    }
  };

  return (
    <>
      <section className="pb-sm-8 pb-5 mb-sm-10 mb-5 border-bottom">
        <div className="container">
          <div className="row gy-5 align-items-center">
            <div className="col-lg-6 col-md-5 text-center">
              <img src={SurveyBanner} alt="" className="img-fluid w-100" />
            </div>
            <div className="col-lg-6 col-md-7">
              {surveyList?.content?.length > 0 && (
                <form
                  onSubmit={(event) => vote(event, surveyList?.content[0]?.id)}
                >
                  <div className="d-flex align-items-center justify-content-md-start justify-content-center mb-5">
                    <h1 className="mb-0">Anket</h1>
                    <span className="text-blue-light small ms-4">
                      {surveyList?.content[0]?.answeredSurvey === true ? (
                        <React.Fragment>
                          Katılımınız için teşekkürler!
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <i className="fas fa-lightbulb me-2"></i>
                          Katıl, {surveyList?.content[0]?.score} puan kazan!
                        </React.Fragment>
                      )}
                    </span>
                  </div>

                  <h4 className="mb-sm-5 mb-4 lh-base text-md-start text-center">
                    {surveyList?.content[0]?.description}
                  </h4>
                  <div className="row row-cols-2 g-sm-4 g-3 mb-sm-5 mb-4">
                    {surveyList?.content[0]?.surveyChoiceDTOList.map(
                      (item, i) => (
                        <div className="col" key={item.id}>
                          <div
                            className={`form-check form-check-lg${
                              surveyList?.content[0]?.answeredSurvey
                                ? " ps-0"
                                : ""
                            }`}
                          >
                            {!surveyList?.content[0]?.answeredSurvey ? (
                              <input
                                className="form-check-input"
                                type="radio"
                                name="surveyChoice"
                                id={"option" + item.id}
                                value={item.id}
                                defaultChecked={i === 0}
                              />
                            ) : null}
                            <label
                              className="form-check-label h6 text-gray-dark mb-0"
                              htmlFor={"option" + item.id}
                            >
                              {item.name.replace(/<\/?[^>]+(>|$)/g, "")}
                              {surveyList?.content[0]?.answeredSurvey ? (
                                <span className="small text-green ms-2">
                                  %
                                  {Math.round(
                                    (item.markedValue /
                                      surveyList?.content[0]
                                        ?.totalParticipant) *
                                      100
                                  )}
                                </span>
                              ) : null}
                            </label>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {!surveyList?.content[0]?.answeredSurvey ? (
                    <div className="text-sm-start text-center">
                      <button
                        type="submit"
                        className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3"
                      >
                        Gönder
                      </button>
                    </div>
                  ) : null}
                </form>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeSurvey;
