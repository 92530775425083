/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction, profileAction } from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Home Components
import HomeAnnouncement from "./HomeAnnouncement";
import HomeProduct from "./HomeProduct";
import HomeLeaderboard from "./HomeLeaderboard";
import HomeSurvey from "./HomeSurvey";
import HomeGames from "./HomeGames";

const Home = () => {
  const dispatch = useDispatch();
  const authUser = useSelector((store) => store.auth);
  const [showLeaderboard, setShowLeaderboard] = useState(false); // Başlangıçta gizli

  useEffect(() => {
    if (authUser) {
      dispatch(profileAction.getTenantMenu(authUser?.authUser?.roleId)).then(
        (res) => {
          if (res.isHttpSuccess && res.data && Array.isArray(res.data)) {
            if (res.data && Array.isArray(res.data)) {
              const tenantPaths = res?.data?.map((item) => item.menu?.pathUrl);

              if (!tenantPaths.includes("/leaderboard")) {
                setShowLeaderboard(true);
              } else {
                setShowLeaderboard(false);
              }
            }
          } else {
            setShowLeaderboard(true);
          }
        }
      );
    }
  }, [authUser]);

  return (
    <>
      <Header />
      <HomeAnnouncement />
      <HomeProduct />
      {showLeaderboard === true && <HomeLeaderboard />}
      <HomeSurvey />
      <HomeGames />
      <Footer />
    </>
  );
};

export default Home;
