import * as academyTypes from "./magazine.type";

const initialState = {
  magazineList: [],
  magazineCategoryList: [],
  tvList: [],
  error: null,
  isLoading: false,
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${academyTypes.GET_MAGAZINE_LIST_PENDING}`:
    case `${academyTypes.GET_TV_LIST_PENDING}`:
    case `${academyTypes.GET_MAGAZINE_CATEGORY_LIST_PENDING}`:
    case `${academyTypes.GET_MAGAZINE_CATEGORY_BY_ID_PENDING}`:
    case `${academyTypes.GET_MAGAZINE_SUB_CATEGORY_BY_ID_PENDING}`:
      return { ...state, isLoading: true, error: null };

    // Magazine List
    case `${academyTypes.GET_MAGAZINE_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        magazineList: payload.data,
      };
    case `${academyTypes.GET_MAGAZINE_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        magazineList: [],
      };

    // TV List
    case `${academyTypes.GET_TV_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        tvList: payload.data,
      };
    case `${academyTypes.GET_TV_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        magazineList: [],
      };

    // Magazine Category List
    case `${academyTypes.GET_MAGAZINE_CATEGORY_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        magazineCategoryList: payload.data,
      };
    case `${academyTypes.GET_MAGAZINE_CATEGORY_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        magazineCategoryList: [],
      };

    // Magazine Category BY ID
    case `${academyTypes.GET_MAGAZINE_CATEGORY_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        magazineList: payload.data,
      };
    case `${academyTypes.GET_MAGAZINE_CATEGORY_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        magazineList: [],
      };

    // Magazine Sub Category BY ID
    case `${academyTypes.GET_MAGAZINE_SUB_CATEGORY_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        magazineList: payload.data,
      };
    case `${academyTypes.GET_MAGAZINE_SUB_CATEGORY_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        magazineList: [],
      };

    // Magazine Category BY ID
    case `${academyTypes.GET_TV_CATEGORY_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        tvList: payload.data,
      };
    case `${academyTypes.GET_TV_CATEGORY_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        tvList: [],
      };

    // TV Sub Category BY ID
    case `${academyTypes.GET_TV_SUB_CATEGORY_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        tvList: payload.data,
      };
    case `${academyTypes.GET_TV_SUB_CATEGORY_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        tvList: [],
      };

    default:
      return state;
  }
};

export default reducer;
