import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profileAction, orderAction } from "../../../redux/modules";
import showErrMessage from "../../../components/ShowErrMessage";
import showMessage from "../../../components/ShowMessage";
import { Modal, Button } from "react-bootstrap";
import styles from "./styles.module.scss";
//react-icons
import { FaTrophy } from "react-icons/fa";

const Orders = () => {
  const dispatch = useDispatch();
  const loginSettings = useSelector((store) => store.auth.loginSettings);
  const orderList = useSelector((store) => store.profile.orderList);
  const [showModal, setShowModal] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [openAccordion, setOpenAccordion] = useState(null);
  const contentRef = useRef(null);

  useEffect(() => {
    dispatch(profileAction.getOrderList());
  }, [dispatch]);

  const toggleAccordion = (orderId) => {
    setOpenAccordion((prevOrderId) =>
      prevOrderId === orderId ? null : orderId
    );
  };

  useEffect(() => {
    if (contentRef.current) {
      if (openAccordion !== null) {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.maxHeight = "0px";
      }
    }
  }, [openAccordion]);

  const handleCancelOrder = (e, id) => {
    e.preventDefault();
    setCurrentOrderId(id);
    setShowModal(true);
  };

  const confirmCancelOrder = () => {
    dispatch(orderAction.cancelOrder(currentOrderId))
      .then((res) => {
        if (res.isHttpSuccess) {
          showMessage("Siparişiniz İptal Edildi!");
          dispatch(profileAction.getOrderList());
        } else {
          showMessage("Siparişiniz İptal Edilemedi!");
        }
      })
      .catch((err) => {
        showErrMessage("Siparişiniz İptal Edilemedi!");
      })
      .finally(() => {
        setShowModal(false);
        setCurrentOrderId(null);
      });
  };

  return (
    <React.Fragment>
      <h2 className="mb-sm-6 mb-4">Sipariş Bilgilerim</h2>
      <p className="mb-sm-5 mb-4 fw-light">
        Siparişlerinizin durumunu bu bölümde detaylı olarak görebilir, takip
        edebilirsiniz.
      </p>

      {orderList ? (
        orderList?.content?.map((item, i) => (
          <div
            className="border rounded-3 mb-4 p-4"
            key={item.id}
            onClick={() => toggleAccordion(item.id)}
          >
            <div className="row align-items-center g-4">
              <div className="col-md-3 justify-content-center flex">
                {item?.orderProductDTOList?.length > 0 && (
                  <>
                    {item?.orderProductDTOList.slice(0, 2).map((product, i) => (
                      <div key={i}>
                        <NavLink
                          to={`/shop/${
                            product?.productDTO?.productContentUrlList?.length >
                            0
                              ? product?.productDTO?.productContentUrlList[0]
                                  ?.productId
                              : "#"
                          }`}
                          className={styles.productLink}
                        >
                          <img
                            src={
                              product?.productDTO?.productContentUrlList
                                ?.length > 0
                                ? product.productDTO.productContentUrlList[0]
                                    .baseAddress
                                : loginSettings?.logo
                            }
                            alt="product"
                            className={
                              item?.orderProductDTOList?.length > 2
                                ? styles.productImage
                                : styles.productImageBig
                            }
                          />
                        </NavLink>
                        {product.productCount > 1 && (
                          <div className="flex justify-content-center mt-2">
                            <span className="badge flex items-center justify-content-center p-2 rounded-full bg-gray text-white  text-sm">
                              +{product.productCount}
                            </span>
                          </div>
                        )}
                      </div>
                    ))}

                    {item.orderProductDTOList.length > 2 && (
                      <div className={styles.remainingImages}>
                        <span className="badge flex items-center justify-center rounded-full bg-red text-white p-1 text-xs">
                          +{item.orderProductDTOList.length - 2}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="col-md-6 ">
                {item?.orderProductDTOList?.length > 0 && (
                  <>
                    <p className="mb-4 opacity-75" style={{ fontSize: "12px" }}>
                      Sipariş No: {item?.orderProductDTOList[0]?.orderId}
                    </p>
                    <h6 className="mb-2">{item.productName}</h6>
                    <p className="mb-0 fw-light opacity-75">
                      {item.score} Puan
                    </p>
                    <p className="mb-0 fw-light opacity-75">
                      {item.paidPrice}TL Fiyat
                    </p>

                    <div className="mt-4">
                      <h6 className="mb-2 small">
                        Sipariş Adresi:{" "}
                        <span className="fw-light">
                          {item.deliveryAddressName}
                        </span>
                      </h6>
                      <p className="small mb-0 opacity-50">
                        {item.deliveryAddress.replace(/null /g, "")}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-3">
                {item.orderStatu === "TESLİM EDİLDİ" ? (
                  <React.Fragment>
                    <h1 className="mb-3 text-green">
                      <i className="fas fa-check-circle"></i>
                    </h1>
                    <h6 className="text-green">Sipariş Durumu</h6>
                    <p className="mb-0 small strong">{item.orderStatu}</p>
                  </React.Fragment>
                ) : item.orderStatu === "KARGOYA TESLİM EDİLDİ" ? (
                  <React.Fragment>
                    <h1 className="mb-3 text-blue-light">
                      <i className="fas fa-paper-plane"></i>
                    </h1>
                    <h6 className="text-blue-light">Sipariş Durumu</h6>
                    <p className="mb-0 small strong">{item.orderStatu}</p>
                  </React.Fragment>
                ) : item.orderStatu === "DAĞITIMA ÇIKARILDI" ? (
                  <React.Fragment>
                    <h1 className="mb-3 text-blue-light">
                      <i className="fas fa-paper-plane"></i>
                    </h1>
                    <h6 className="text-blue-light">Sipariş Durumu</h6>
                    <p className="mb-0 small strong">{item.orderStatu}</p>
                  </React.Fragment>
                ) : item.orderStatu === "SİPARİŞİNİZ HAZIRLANIYOR" ? (
                  <React.Fragment>
                    <h1 className="mb-3 text-orange">
                      <i className="fas fa-clock fw-light"></i>
                    </h1>
                    <h6 className="text-orange">Sipariş Durumu</h6>
                    <p className="mb-0 small strong">{item.orderStatu}</p>
                  </React.Fragment>
                ) : item.orderStatu === "SİPARİŞ ALINDI" ? (
                  <>
                    <React.Fragment>
                      <h6>Sipariş Durumu</h6>
                      <p className="mb-0 small strong">{item.orderStatu}</p>
                    </React.Fragment>

                    <div>
                      <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={(e) => handleCancelOrder(e, item.id)}
                      >
                        İptal Et
                      </button>
                    </div>
                  </>
                ) : (
                  <React.Fragment>
                    <h6>Sipariş Durumu</h6>
                    <p className="mb-0 small strong">{item.orderStatu}</p>
                  </React.Fragment>
                )}
              </div>
            </div>

            {/* Accordion content */}
            {openAccordion === item.id && (
              <div
                className={`${styles.accordionContent} ${
                  openAccordion ? styles.accordionOpen : ""
                }`}
                ref={contentRef}
              >
                <p className="strong">Siparişteki Ürünler</p>

                {item.orderProductDTOList.map((product, i) => (
                  <div key={i} className="flex gap-3">
                    <img
                      src={
                        product.productDTO.productContentUrlList[0].baseAddress
                      }
                      alt="product"
                      className={styles.accordionImage}
                    />
                    <p className="fw-light">{product.productDTO.name}</p>
                    <p>-</p>
                    <span className="d-block medium text-blue mb-4">
                      {product.productDTO.score}&nbsp;
                      <FaTrophy />
                      &nbsp; -
                    </span>
                    <span className="d-block medium text-blue mb-4">
                      +{product.productCount}&nbsp; adet
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="text-center mt-8">
          <h1 className="mb-3 opacity-75">
            <i className="fas fa-shopping-basket"></i>
          </h1>
          <h3 className="mb-4 opacity-75">Hiç siparişiniz yok</h3>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="centered-modal"
      >
        <Modal.Header>
          <Modal.Title>İptal Onayı</Modal.Title>
        </Modal.Header>
        <Modal.Body>İptal etmek istediğinizden emin misiniz?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Vazgeç
          </Button>
          <Button variant="btn btn-red" onClick={confirmCancelOrder}>
            İptal Et
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Orders;
