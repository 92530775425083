/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesAction, profileAction } from "../../redux/modules";

// Components
import GlobalLoader from "../../components/GlobalLoader";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// Styles
import "./styles.scss";

// Images
import BgDots from "../../assets/images/games/bg-pattern.png";
import Girls from "../../assets/images/games/lucky-wheel/girls.png";

// CountdownCircleTimer
import { CountdownCircleTimer } from "react-countdown-circle-timer";

// Toastify
import showErrMessage from "../../components/ShowErrMessage";

const GameWhoWantsScore = () => {
  const dispatch = useDispatch();

  // Game wws
  const wws = useSelector((store) => store.games.wws);

  // Game wws Control
  const wwsControl = useSelector((store) => store.games.wwsControl);

  // Game wws wwsStart
  const wwsStart = useSelector((store) => store.games.wwsStart);

  // Letter
  const [letter, setLetter] = useState("");

  // Start Game
  const [gameStarted, setGameStarted] = useState(false);

  // Game is Finish?
  const [isFinish, setIsFinish] = useState(false);

  // Response Message
  const [responseMessage, setResponseMessage] = useState("");
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  // Timer Defaults
  const [timerColors] = useState(["#76e17b", "#F7B801"]);
  const [timerColorsTime, setTimerColorsTime] = useState([90, 0]);
  const [timerDuration, setTimerDuration] = useState(0);

  // Right of Answer
  const [numberOfTry, setNumberOfTry] = useState(0);
  const [numberOfLetterTry, setNumberOfLetterTry] = useState(0);

  const [point, setPoint] = useState(null);

  const [callFix, setCallFix] = useState(false);

  // CountdownTimer When Complete
  const handleTimerComplete = () => {
    dispatch(gamesAction.wwsSaveAnswer(wwsStart?.id))
      .then((res) => {
        if (res.isHttpSuccess) {
          setIsFinish(true);
          setIsTimeout(true);
          setTimerDuration(0);
          setResponseMessage(res.data?.responseText);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        if (err.isHttpError) {
          setIsFinish(true);
          setIsTimeout(true);
          setTimerDuration(0);
          setResponseMessage("Zamanında cevaplayamadınız!");
        }
      });
  };

  // Begin Game
  const handleBeginGame = () => {
    dispatch(gamesAction.getWwsStart())
      .then((res) => {
        if (res.isHttpSuccess && res.data) {
          setGameStarted(true);
          
          // Önce boş wordList'i oluştur
          const contentList = (res.data.wordList || []).map(length => 
            Array(length).fill("")
          );

          // Eğer wwsControl'de tahminler varsa, onları yerleştir
          if (wwsControl?.guessLetterDTOS?.length > 0 && contentList.length > 0) {
            wwsControl.guessLetterDTOS.forEach(guess => {
              if (guess?.word !== undefined && 
                  guess?.index !== undefined && 
                  guess?.letter &&
                  contentList[guess.word]) {
                contentList[guess.word][guess.index] = guess.letter;
              }
            });
          }
        }
      })
      .catch((err) => {
        console.error("Oyun başlatma hatası:", err);
      });
  };

  const handleWwsSaveAnswer = (e) => {
    e.preventDefault();
    dispatch(gamesAction.wwsSaveAnswer(wwsStart?.id))
        .then((response) => {
          // Success
          if (response.isHttpSuccess) {
            let message = response.data.responseText;
            message = message.replace("{point}", response.data.score);
            setResponseMessage(message);

            setPoint(response?.data.score);

            if (response.data.answerTrue === true) {
              setIsCorrectAnswer(true);
              setIsFinish(true);
              setTimeout(() => {
                dispatch(profileAction.getLoginUserDetail());
              }, 2000);
            } else if (response.data.answerFalse === true) {
              if (numberOfTry > 1) {
                setIsCorrectAnswer(false);
                setIsFinish(false);
                showErrMessage(response.data.responseText);
              } else {
                setIsCorrectAnswer(false);
                setIsFinish(true);
              }
            } else if (response.data.answerTimeOut === true) {
              setIsTimeout(true);
              setIsFinish(true);
            }

            setLetter("");
            setNumberOfTry(numberOfTry - 1);
          }
        })
        .catch((err) => {
          // Error
          if (err.isHttpError) {
            console.log(err.isHttpError);
            setLetter("");
          }
        });
  };

  useEffect(() => {
    dispatch(gamesAction.getWws());
    dispatch(gamesAction.getWwsControl());

    return () => {};
  }, [dispatch]);

  // Defaults
  useEffect(() => {
    if (wwsControl) {
      // Timer
      setTimerDuration(wwsControl.remainTime);

      // Timer Colors
      setTimerColorsTime([wwsControl.remainTime, 0]);
      setGameStarted(
        wwsControl?.gameConditionStatus === "CONTINUES" ? true : false
      );

      if (
        wwsControl?.gameConditionStatus === "CONTINUES" &&
        wwsControl.remainTime > 0 && 
        !callFix
      ) {
        setCallFix(true);
        handleBeginGame();
      }

      setPoint(wwsControl.score);
    }
  }, [wwsControl]);

  return (
    <>
      <main className="main-container game-word-puzzles">
        <Header />
        <section
          className="games-page page-container wheel-page word-puzzles-page"
          id="backgroundPattern"
          style={{ backgroundImage: wws?.backgroundPattern }}
        >
          <div
            className="games-page-introduction"
            style={{ backgroundImage: `url(${BgDots})` }}
            id="backgroundImage"
          >
            <div
              className={
                gameStarted && isFinish === false
                  ? "d-block game-timer"
                  : "d-none game-timer"
              }
            >
              <h3 className="text-center">Kalan Süre</h3>
              <div className="game-countdown text-center ml-auto mr-auto">
                {wwsStart?.remainTime && timerDuration && (
                  <CountdownCircleTimer
                    isPlaying
                    duration={wwsStart?.remainTime}
                    initialRemainingTime={timerDuration}
                    colors={timerColors}
                    colorsTime={timerColorsTime}
                    size={80}
                    strokeWidth={8}
                    onComplete={() => handleTimerComplete()}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                )}
              </div>

              <div className="text-center">
                <h3>Cevap Hakkı</h3>
                <p className="text-center">{numberOfTry}</p>
              </div>

              <div className="text-center">
                <h3 className="pt-2">Harf İste</h3>
                <p className="text-center">{numberOfLetterTry}</p>
              </div>
            </div>

            <Container>
              {isFinish === false && (
                <Row className="justify-content-md-center text-center text-white">
                  <>
                    {wwsControl?.remainTime > 0 ? (
                      <>
                        {gameStarted === false ? (
                          <Col xs="12" sm="12" md="8" lg="6" xl="4">
                            <div className="d-flex flex-column justify-content-center align-items-center wrapper-wp-start">
                              <div className="games-page-circle mb-md-5 mb-3">
                                <img src={wws?.icon} alt={wws?.title} />
                              </div>
                              <h2 className="mb-3 mb-md-4">{wws?.title}</h2>
                              <p>{wws?.description}</p>

                              {numberOfTry ? (
                                <Button
                                  onClick={() => handleBeginGame()}
                                  className="btn btn-success mt-5 mb-0 text-white"
                                >
                                  {wws?.buttonText}
                                </Button>
                              ) : (
                                <div className="mt-3">
                                  Daha önceden yarışmaya katıldınız. Lütfen daha
                                  sonra tekrar deneyiniz.
                                </div>
                              )}
                            </div>
                          </Col>
                        ) : (
                          <Col>
                            <div className="wws-competition">
                              <div className="wws-information">
                                <h2 className="mb-3 mb-md-4">
                                  {wwsStart?.question}
                                </h2>
                              </div>

                              <div className="wws-guess-letters">
                                <h3 className="mb-4 fs-36">{point} Puan</h3>

                                <h5>Size Verilen Harfler</h5>
                                <ul className="!mt-3 mb-0">
                                  {wwsStart &&
                                    wwsStart?.guessLetterList?.length > 0 &&
                                    wwsStart?.guessLetterList.map((item) => (
                                      <li key={item}>{item}</li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </Col>
                        )}
                      </>
                    ) : (
                      <div className="flex flex-column">
                        <div>
                          {/* wws Entrance */}
                          <div className="d-flex flex-column justify-content-center align-items-center wrapper-wp-start">
                            {wws?.icon && (
                              <div className="games-page-circle mb-md-5 mb-3">
                                <img src={wws?.icon} alt={wws?.title} />
                              </div>
                            )}
                            {wws?.title && (
                              <h2 className="m-0">{wws?.title}</h2>
                            )}
                          </div>
                          {/*  End wws Entrance */}

                          {/* wws Manipulated */}
                          {wws ? (
                            <div className="mt-3">
                              {(() => {
                                switch (wwsControl?.gameConditionStatus) {
                                  case "PLAYED":
                                    return "Daha önceden yarışmaya katıldınız. Lütfen daha sonra tekrar deneyiniz.";
                                  case "CONTINUES":
                                    return "Yarışma devam ediyor. Başarılar!";
                                  case "NO_GAME":
                                    return "Şu anda aktif oyun bulunmamaktadır. Lütfen daha sonra tekrar deneyiniz.";
                                  case "TIMEOUT":
                                    return "Cevaplama süresi doldu. Bir sonraki yarışmada görüşmek üzere!";
                                  default:
                                    return "Durum belirlenemedi. Lütfen daha sonra tekrar deneyiniz.";
                                }
                              })()}
                            </div>
                          ) : (
                            <GlobalLoader
                              isLoading={true}
                              color={"#fff"}
                              position={"absolute"}
                            />
                          )}
                          {/*  End wws Manipulated */}
                        </div>
                      </div>
                    )}
                  </>
                </Row>
              )}

              {isFinish === true && (
                <Row className="justify-content-md-center">
                  {isTimeout === true ? (
                    <Col xs={12} className="text-center find-mate-win active">
                      <h1>Zamanında Cevaplayamadınız!</h1>
                      {responseMessage && <p>{responseMessage}</p>}
                      <div className="wrapper-reward active">
                        <img src={Girls} alt="" />
                        <h4>0</h4>
                      </div>
                    </Col>
                  ) : (
                    <>
                      {isCorrectAnswer === true ? (
                        <Col
                          xs={12}
                          className="text-center find-mate-win active"
                        >
                          <h1>Tebrikler Kazandınız!</h1>
                          <p className="!m-0">{responseMessage}</p>

                          <div className="wrapper-reward active">
                            <img src={Girls} alt="" />
                            {point && <h4>{point}</h4>}
                          </div>
                        </Col>
                      ) : (
                        <Col
                          xs={12}
                          className="text-center find-mate-win active"
                        >
                          <h1>Kaybettiniz!</h1>
                          <p className="!m-0">{responseMessage}</p>

                          <div className="wrapper-reward active">
                            <img src={Girls} alt="" />
                            <h4>0</h4>
                          </div>
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              )}
            </Container>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default GameWhoWantsScore;
