import * as academyTypes from "./academy.type";
import { API } from "../../../services/http.service";

const REQUEST_URL = "";

// Academy List
export const getAcademyList = () => async (dispatch) => {
  dispatch({ type: academyTypes.GET_ACADEMY_LIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/academy/get/all/video/topics`
    );
    dispatch({
      type: academyTypes.GET_ACADEMY_LIST_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: academyTypes.GET_ACADEMY_LIST_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Academy Category List
export const getAcademyCategoryList = () => async (dispatch) => {
  dispatch({ type: academyTypes.GET_ACADEMY_CATEGORY_LIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/academy/get/all/video/categories`
    );
    dispatch({
      type: academyTypes.GET_ACADEMY_CATEGORY_LIST_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: academyTypes.GET_ACADEMY_CATEGORY_LIST_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Academy Category Filter By Id
export const getAcademyCategoryFilterById =
  (categoryId) => async (dispatch) => {
    dispatch({ type: academyTypes.POST_ACADEMY_CATEGORY_FILTER_PENDING });

    try {
      const response = await API.get(
        `${REQUEST_URL}/academy/get/all/video/topics/for/category/${categoryId}`
      );
      dispatch({
        type: academyTypes.POST_ACADEMY_CATEGORY_FILTER_FULFILLED,
        payload: response,
      });
      return Promise.resolve(response);
    } catch (error) {
      dispatch({
        type: academyTypes.POST_ACADEMY_CATEGORY_FILTER_REJECTED,
        payload: error,
      });
      return Promise.reject(error);
    }
  };

// Academy Detail List
export const getAcademyDetailList = (topicId) => async (dispatch) => {
  dispatch({ type: academyTypes.GET_ACADEMY_DETAIL_LIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/academy/get/all/video/details/topic/and/subtopic/${topicId}`
    );
    dispatch({
      type: academyTypes.GET_ACADEMY_DETAIL_LIST_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: academyTypes.GET_ACADEMY_DETAIL_LIST_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const getAcademyQuestions = (topicId) => async (dispatch) => {
  dispatch({ type: academyTypes.GET_ACADEMY_QUESTIONS_DETAIL_LIST_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/academy/get/all/video/details/video/topic/${topicId}`
    );
    dispatch({
      type: academyTypes.GET_ACADEMY_QUESTIONS_DETAIL_LIST_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: academyTypes.GET_ACADEMY_QUESTIONS_DETAIL_LIST_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const getVideoDetail = (videoId) => async (dispatch) => {
  dispatch({ type: academyTypes.GET_VIDEO_DETAIL_PENDING });

  const data = {
    videoId,
  };

  try {
    const response = await API.post(`${REQUEST_URL}/get/video/detail`, data);
    dispatch({
      type: academyTypes.GET_VIDEO_DETAIL_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: academyTypes.GET_VIDEO_DETAIL_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const getVideoSeconds = (videoId) => async (dispatch) => {
  dispatch({ type: academyTypes.GET_VIDEO_SECONDS_PENDING });

  const data = {
    videoId,
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/save/video/last/second`,
      data
    );
    dispatch({
      type: academyTypes.GET_VIDEO_SECONDS_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: academyTypes.GET_VIDEO_SECONDS_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};
