export const GET_ANNOUNCEMENTLIST_PENDING = "GET_ANNOUNCEMENTLIST_PENDING";
export const GET_ANNOUNCEMENTLIST_FULFILLED = "GET_ANNOUNCEMENTLIST_FULFILLED";
export const GET_ANNOUNCEMENTLIST_REJECTED = "GET_ANNOUNCEMENTLIST_REJECTED";

export const SAVE_SURVEY_ANSWER_PENDING = "SAVE_SURVEY_ANSWER_PENDING";
export const SAVE_SURVEY_ANSWER_FULFILLED = "SAVE_SURVEY_ANSWER_FULFILLED";
export const SAVE_SURVEY_ANSWER_REJECTED = "SAVE_SURVEY_ANSWER_REJECTED";

export const GET_DISCLAIMER_BY_FIRMID_PENDING =
  "GET_DISCLAIMER_BY_FIRMID_PENDING";
export const GET_DISCLAIMER_BY_FIRMID_FULFILLED =
  "GET_DISCLAIMER_BY_FIRMID_FULFILLED";
export const GET_DISCLAIMER_BY_FIRMID_REJECTED =
  "GET_DISCLAIMER_BY_FIRMID_REJECTED";

export const SAVE_DISCLAIMER_CHECK_PENDING = "SAVE_DISCLAIMER_CHECK_PENDING";
export const SAVE_DISCLAIMER_CHECK_FULFILLED =
  "SAVE_DISCLAIMER_CHECK_FULFILLED";
export const SAVE_DISCLAIMER_CHECK_REJECTED = "SAVE_DISCLAIMER_CHECK_REJECTED";

export const SELECTED_OPTION = "SELECTED_OPTION";

export const GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING =
  "GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING";
export const GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED =
  "GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED";
export const GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED =
  "GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED";

export const GET_BRAND_LIST_PENDING = "GET_BRAND_LIST_PENDING";
export const GET_BRAND_LIST_FULFILLED = "GET_BRAND_LIST_FULFILLED";
export const GET_BRAND_LIST_REJECTED = "GET_BRAND_LIST_REJECTED";

export const GET_PRODUCT_LIST_PENDING = "GET_PRODUCT_LIST_PENDING";
export const GET_PRODUCT_LIST_FULFILLED = "GET_PRODUCT_LIST_FULFILLED";
export const GET_PRODUCT_LIST_REJECTED = "GET_PRODUCT_LIST_REJECTED";

export const GET_SURVEY_LIST_HOMEPAGE_PENDING =
  "GET_SURVEY_LIST_HOMEPAGE_PENDING";
export const GET_SURVEY_LIST_HOMEPAGE_FULFILLED =
  "GET_SURVEY_LIST_HOMEPAGE_FULFILLED";
export const GET_SURVEY_LIST_HOMEPAGE_REJECTED =
  "GET_SURVEY_LIST_HOMEPAGE_REJECTED";

export const SET_SURVEY_ANSWER_HOMEPAGE_PENDING =
  "SET_SURVEY_ANSWER_HOMEPAGE_PENDING";
export const SET_SURVEY_ANSWER_HOMEPAGE_FULFILLED =
  "SET_SURVEY_ANSWER_HOMEPAGE_FULFILLED";
export const SET_SURVEY_ANSWER_HOMEPAGE_REJECTED =
  "SET_SURVEY_ANSWER_HOMEPAGE_REJECTED";

export const SET_ADD_TO_CART_PENDING = "SET_ADD_TO_CART_PENDING";
export const SET_ADD_TO_CART_FULFILLED = "SET_ADD_TO_CART_FULFILLED";
export const SET_ADD_TO_CART_REJECTED = "SET_ADD_TO_CART_REJECTED";

export const GET_SURVEY_RESULT_PENDING = "GET_SURVEY_RESULT_PENDING";
export const GET_SURVEY_RESULT_FULFILLED = "GET_SURVEY_RESULT_FULFILLED";
export const GET_SURVEY_RESULT_REJECTED = "GET_SURVEY_RESULT_REJECTED";

export const GET_HOMEPAGE_LEADERBOARD = "GET_HOMEPAGE_LEADERBOARD";
